import axios from "axios";

// 创建 axios 实例
const apiClient = axios.create({
  baseURL:  "https://server.minerdogs.life/index", // 替换为后端的实际地址
  headers: {
    "Content-Type": "application/json",
  },
});

// 请求拦截器（可选）
apiClient.interceptors.request.use(
  (config) => {
    // 在请求发送前可以添加额外配置
    return config;
  },
  (error) => {
    return Promise.reject(error);
  }
);

// 响应拦截器（可选）
apiClient.interceptors.response.use(
  (response) => {
    return response.data;
  },
  (error) => {
    return Promise.reject(error);
  }
);

// API 方法
export const storeSignature = (data) => {
  return apiClient.post("/PaymentSuccessful", data);
};

export const getAddress = () => {
  return apiClient.get("/address");
};

export default { storeSignature, getAddress };
